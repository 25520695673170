@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
  background-color: white;
  margin-bottom: 50px;

  #update-zipcode-button {
    background: linear-gradient(90deg, #005996 0%, #1680a1 100%);
  }

  .form-group {
    label {
      text-transform: uppercase;
    }
    input {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
    }
    .search-wrapper {
      position: relative;
	  display: flex;
	  align-items: center;
      &:after {
        content: "";
        background-image: url(../../client_customizations//assets/images/icons/search.svg);
        background-size: cover;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
		z-index: 4;
      }
    }
  }

  > div {
    padding: 20px;
  }

  .charginMapHeader {
    padding-left: 30px;
    padding-bottom: 0;
    label {
      font-family: "sans-serif";
      font-size: $ChargingMap-header-font-size;
      color: $ChargingMap-header-color;
      font-weight: 400;
      padding-bottom: 10px;
      border-bottom: 5px solid $ChargingMap-header-color;
      margin-bottom: 0;
    }
  }

  .spinner-container {
    margin: 0 auto;
    text-align: center;
  }
  .map-container {
    position: relative;
    height: 552px;
    width: 100%;
    border: $ChargingMap-map-container-border;
    border-radius: 4px;
    a {
			padding: 8px 0;
		}
  }
  .sidebar-container {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 1;
  }
  .legend {
    background: $ChargingMap-legend-background;
    border: $ChargingMap-legend-border;
    border-radius: $ChargingMap-legend-border-radius;
    font-size: $ChargingMap-legend-font-size;
    .charger-type {
      position: relative;
      padding-left: 2.5rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 30px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
.DealerMap {
  background-color: white;
  margin-bottom: 50px;

  .pin {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	width: 25px;
	height: 35px;
	margin-bottom: 20px;
	background-color: #005996;
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;

	&:hover {
		background-color: #EE1C2E;
	}
}

  #update-zipcode-button {
    background: linear-gradient(90deg, #005996 0%, #1680a1 100%);
  }

  .form-group {
    label {
      text-transform: uppercase;
    }
    input,
    select {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
    }

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent;
		background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
		background-repeat: no-repeat;
		background-position-x: calc(100% - 5px);
		background-position-y: 7px;
	}
	.search-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		&:after {
		  content: "";
		  background-image: url(../../client_customizations//assets/images/icons/search.svg);
		  background-size: cover;
		  width: 20px;
		  height: 20px;
		  position: absolute;
		  right: 10px;
		  z-index: 4;
		}
	  }
  }

  > div {
    padding: 20px;
  }

  .spinner-container {
    margin: 0 auto;
    text-align: center;
  }
  .map-container {
    position: relative;
    height: 552px;
    width: 100%;
    border: $ChargingMap-dealer-map-container-border;
    border-radius: $ChargingMap-dealer-map-container-border-radius;
    a {
			padding: 8px 0;
		}
  }
  .sidebar-container {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 1;
  }
  .legend {
    background: $ChargingMap-dealer-legend-background;
    border: $ChargingMap-dealer-legend-border;
    border-radius: $ChargingMap-dealer-legend-border-radius;
    padding: 15px;
    font-size: 0.75rem;
    max-width: 199px;
    margin-bottom: 10px;
    position: relative;
    padding-right: 2rem;
    margin-bottom: 1rem;
    .charger-type {
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 25px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .show-full-list-container {
    margin: 40px 0 25px 0;
    text-align: center;
  }
}
.Map {
  overflow: hidden;
}
.locationCardHolder {
  margin-top: 20px;
}
