@import "src/client_customizations/scss/client_master.scss"; 

.ChargerCard p {
  width: 100%;
  margin-bottom: 0;
}

.ChargerCard {
  border-radius: 0 !important;
  height: 409px !important;
  border: 1px solid #E4E4E4 !important;

  @media(max-width: 768px){
    height: auto !important;
    min-height: 409px !important;
  }

  @media(max-width: 768px){
    height: auto !important;
    min-height: 409px !important;
  }

  @media(max-width: 576px){
    width: 100% !important;
  }
}

.ChargerCardTop, .ChargerCardBottom > * {
  padding: 8px;
}

.ChargerCardTop {
  position: relative;
  padding: 15px 2px 10px 15px !important;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .imageContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    @media(max-width: 768px){
      flex-direction: column-reverse;
    }
  }

  .charger-compatibility-warning {
    font-size: 10px;
    margin: 1em auto;
  }

  .badge-locally-available, .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}

.ChargerCardBottom {

  .PriceRow{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    span{
      margin-right: 8px;
    }
  }

//   border-top: $EVCard-EVCardBottom-border;
  display: flex;
  flex-direction: column;

//   > * {
//     flex-grow: 1;
//     padding-top: 10px;
//     padding-bottom: 10px;
//   }

//   div:first-child {
//     border-right: $EVCard-EVCardBottom-border;
//   }
}

.buy-charger {
    text-align: center;

    .btn{
      padding: 8px 40px !important;
      background: linear-gradient(90deg, #005996 0%, #1680A1 100%) !important;
      font-weight: 600 !important;
      font-size: 16px !important;
    }
}

.ChargerCardBottomWithLink {
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

.charger-row {
  display:flex;
  margin-bottom: 14px;

    p {
      color: #505357 !important;
      padding-left: 8px;
    }
}

.special-row{
  width: auto;
  position: absolute;
  bottom: 0;
  margin-bottom: -15px; 

  @media(max-width: 1200px){
    margin-bottom: -25px; 
  }

  @media(max-width: 921px){
    margin-bottom: -20px; 
  }
}

.charger-price {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #333333 !important;
}

@media (min-width: 576px) {
  .ChargerCardTop {
    padding: 15px 15px 10px 15px;
  }

  .ChargerCardBottom > * {
    padding: 10px 15px;
  }

  .ChargerCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}