@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	border-top: 1px solid $GenericFooter-border-color;
	.container {
              border-bottom:1px solid #ccc;

              &.no-border-bottom {
                     border-bottom: none;
              }

		@media (min-width: 990px){
                     &.padding-left {
                            padding-left: 140px;
                     }
		}
	}

       .disclaimer_container {
              padding:25px 0;

              div {
                     display:flex;
                     justify-content: center;
                     flex-wrap: wrap;

                     img {
                            max-width: 100%;
                            margin-bottom:15px;
                     }

                     p {
                            color:#113653;
                            text-align: center;
                            margin:0;
                            font-size:12px;
                     }
              }

              .jdp-logo-footer {
                     display:block;
                     margin:0 auto;
              }
       }

	h2 {
		font-size: 15px;
		font-weight: 700;
	}

	span {
		font-size: 13px;
	}

	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: underline;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
}