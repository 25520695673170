@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 400px;
	margin: 0 auto;
}
.link-card {
	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 80px;
	position: relative;
	cursor: pointer;
	.icon {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height: 30px;
		margin-bottom: 20px;
		background-color: $LinkCard-card-font-color;
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
		text-decoration: none;
		color: $LinkCard-card-font-color;
		font-weight: 700;
	}
	&:hover {
		p {
			color: $LinkCard-card-font-hover-color;
		}
		.icon {
			background-color: $LinkCard-card-font-hover-color;
		}
	}
	.evc-card {
		transition: .1s ease-in-out;
		background-color: $LinkCard-evc-card-background-color;
		border: 1px solid transparent;
		padding-top: 40px;
		padding-bottom: 40px;
		border-radius: 0px;
		p {
			font-size: 0.7rem;
		}
		> div {
			transition: .1s ease-in-out;
		}
		&:hover {
			transform: scale(1.15) translateY(5px);
			position: absolute;
			box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15);

			> div {
				transform: translateY(-10px);
			}
			
		}
	}
}
@media (min-width: 576px) {
	.link-card-container {
		max-width: 75%;
	}
	.link-card {
		flex-basis: 120px;
		.icon {
			width: 100%;
			height: 45px;
		}
		.evc-card {
			p {
				font-size: 0.85rem;
			}
		}
	}
}
@media (min-width: 768px) {
	.link-card {
		flex-basis: 160px;
	}
}
