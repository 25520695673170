@import "src/client_customizations/scss/client_master.scss";

.IncentivePreferences {
	.h5 {
		.badge {
			font-size: 50%;
			vertical-align: middle;
		}
	}
	.tooltip-bubble {
		min-width: 250px;
		max-width: 250px;
	}
	.input-well {
		background-color: $IncentivePreferences-input-well-background-color;
		padding: 0;
		border-radius: 0;
		border: none;
		.btn {
			width: 100%;
		}
	}
}


.incentive-prefs {
	.btn-ae {
		-webkit-appearance: none;
		background: rgb(0,89,150);
		background: -moz-linear-gradient(90deg, rgba(0,89,150,1) 0%, rgba(22,128,161,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(0,89,150,1) 0%, rgba(22,128,161,1) 100%);
		background: linear-gradient(90deg, rgba(0,89,150,1) 0%, rgba(22,128,161,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#005996",endColorstr="#1680a1",GradientType=1);
	}
}