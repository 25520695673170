@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
  background-color: $Header-background-color;
  border-bottom: $Header-border-bottom;
  a {
    color: $Header-link-color;
  }

  .container{
    padding: 0;
		@media (min-width: 992px){
			padding-left: 140px;
		}
	}

  .dropdown {
    > a.ev {
      display: flex;
      justify-content: center;
      align-items: center;

      span {

        &:after {
          margin-left: 5px;
          content: url(../../../assets/images/arrow.svg);
          display: inline-block;
          width: 16px;
          height: 20px;
        }
        display: inline-block;
      }
    }

    &:hover,
    &.show {
      > a.ev {
        height: 60px;

        span {
          &:after {
            transform: scaleY(-1);
          }
        }

        + .dropdown-menu {
          display: block;
          left: 15px;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 5px;
        }
      }
    }

    .dropdown-menu {
      :hover {
        display: block;
      }
    }
  }

  .navbar {
    padding-top: 0;
    padding-bottom: 0;
    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      img {
        width: 160px;
      }
    }
    .navbar-collapse {

      a {
        &.active{
          span {
            position: relative;
            &:before {
              content: " ";
              background-color: $Header-navbar-link-color;
              width: 100%;
              height: 3px;
              position: absolute;
              bottom: -6px;
            }
          }
        }

        &.lang {
          + .dropdown-menu {
            a {
              span {
                position: relative;
                &:before {
                  content: "";
                  width: 0px;
                  height: 0px;
                }
              }
            }

            .dropdown-item {
              &.active {
                span {
                  color: $Header-link-color;
                  position: relative;
                  &:before {
                    content: " ";
                    background-color: $Header-navbar-link-color;
                    width: 3px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -7px;
                  }
                }
              }
            }
          }
          }
      }

      .navbar-nav {
        align-items: center;
      }

      .dropdown-item {
        border-radius: 0;
      }
    }

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;
      border: none;
      border-radius: 0;
      width: 85%;
      min-width: 80px;

      .dropdown-item {
        font-size: 13px;
        padding: 7px;

        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
  }
  .nav-item {
    margin: 5px;
  }
}
.skip-to-content-link {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
}
a.skip-to-content-link {
  &:active {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
  &:focus {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
  &:hover {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
}
.navbar-toggler {
  border-color: $Header-navbar-toggler-border-color;
  color: $Header-navbar-toggler-color;
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}
@media (min-width: 992px) {
  .Header {
    .navbar {
      padding: 0;
      .navbar-collapse {
        a {
          padding: 10px;
          border-radius: 4px;
          font-weight: 700;
          letter-spacing: 0.6px;
          text-decoration: none;
        }
        a.ev {
          display: contents;
        }
        a.active {
          font-weight: bold;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .Header {
    .navbar {
      .navbar-collapse{
        a.ev {
          display: flex;
        }
      }
    }
  }
}
