@import "src/client_customizations/scss/client_master.scss";

//css3 @property to define vars and get transitions

@property --button-background-color-1 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --button-background-color-2 {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

@property --button-text-color {
    syntax: "<color>";
    initial-value: #005996;
    inherits: false;
  }

@property --switch-background-color-1 {
  syntax: "<color>";
  initial-value: #005996;
  inherits: false;
}

@property --switch-background-color-2 {
    syntax: "<color>";
  initial-value: #005996;
  inherits: false;
}

.input-well {
  .label-style, label {
    font-style: normal;
    font-weight: 400;
  }

  .fuel-type-option {
    display: flex;
    align-items: center;
    position: relative;

    + .fuel-type-option {
      margin-top: 10px;
    }

    .switch {
      position: absolute;
      right: 0;
    }
  }

  .badge {
    img {
      width: 20px;
    }

    + span {
      margin-left: 5px;
    }
  }
  .form-group {
    button {
      outline: none;
      background: white;
      font-size: 12px;
      font-weight: 600;
      height: 48px;
      border-radius: 0;
      border: 1px solid #b0b0b0;
      box-sizing: border-box;
      color: var(--button-text-color);
      box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.9);
      background: linear-gradient(90deg, var(--button-background-color-1) 0%, var(--button-background-color-2) 100%);
      transition: --button-background-color-1 .4s, --button-background-color-2 .4s, --button-text-color .4s;
      &.active {
        box-shadow: none;
        --button-background-color-1:  #005996;
        --button-background-color-2: #1680a1;
        --button-text-color: white;
      }
    }
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  // width: 40px;
  // height: 24px;
  float:right;
}

.clearfix {
  clear: both;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    270deg,
    var(--switch-background-color-1) -12.11%,
    var(--switch-background-color-2) 84.36%
  );
  // -webkit-transition: background-image 4s linear;
  // transition: background-image 4s linear;
  transition: --switch-background-color-1 0.4s, --switch-background-color-2 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  // background-image: linear-gradient(270deg, #EE3761 -12.11%, #FAA41B 84.36%);
  --switch-background-color-1: #ee3761;
  --switch-background-color-2: #faa41b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
