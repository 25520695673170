@import "src/client_customizations/scss/client_master.scss"; 

.modal-body {
    .new-used-buttons-wrapper {
        margin-top: 0px;
    }
}

.new-used-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 24px;
    box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);

    a {
        text-transform: uppercase;
        flex:1;
        height: 64px;
       
        button {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background: white;
            font-size: 16px;
            font-weight: 600;
    
            &.active{
                background: linear-gradient(90deg, #005996 0%, #1680A1 100%);
                color: white;
            }
        }
    }
}

.input-well{
    margin-top: 25px;
    border-radius: 0;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
    z-index: inherit;
}

.MatchScoreOptions {

    .input-group {
        select{
            border-radius: 0;
            box-sizing: border-box;
            border: 0.625828px solid #B0B0B0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: black;
            height: 48px;
        }
    }
    
    p.h2 {
        margin-bottom: 20px;
    }

    p, label {
        position: relative;
    }

    img {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        right: -5px;
        bottom: 0;
        top: 1px;
    }

    label {
        img {
            top: -1px;
            right: -25px;
        }
    }

    .form-group {
        label {
            font-weight: normal;
            color: black;
            margin-bottom: 10px;
        }
    }

    span {
        color: #205694;
        font-size: 14px;
        &:after{
            content: ""; 
            border: solid #205694;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            margin-left: 5px;
        }
    }

}