@import "src/client_customizations/scss/client_master.scss"; 

.PricePanels {
	background-color: $PricePanels-background-color;
	border: $PricePanels-border;

	.Nav {
		border-bottom: $PricePanels-Nav-border;
		margin: 0;
		padding: 0;
	}

	.Panel-title{
		font-weight: bold;
		font-size: 40px;
		text-align: center;
	}

	.Panel-subtitle{
		font-weight: bold;
		font-size: 24px;
		text-align: start;
	}

	li {
		a {
			font-weight: regular !important;
			font-size: 16px !important;
			color: black !important;
			cursor: pointer;
			margin-top: 16px;
			margin-bottom: 10px;
			&:hover {
				text-decoration: none;
			}
		}
		a.active {
			span {
				padding-bottom: 15px !important;
				border-bottom: 6px solid #3176B3 !important;
			}
		}
	}
	.nav-pills {
		.nav-link.active {
			background-color: $PricePanels-nav-pills-nav-link-background-color_active;
			color: $PricePanels-nav-pills-nav-link-font-color_active;
		}
	}
	.tab-pane {
		padding: 0;
		.PanelItem {
			border-top: $PricePanels-tab-pane-PanelItem-border;
			padding: 15px;
			margin-top: 2px;
		}
		.PanelItemTop {
			display: inline-block;
			text-align: left;
		}
	}
}
.MatchScoreBadge {
	text-align: center;
	background-color: $MatchScoreBadge-background-color;
	padding: 5px;
	border: $MatchScoreBadge-border;
	margin-top: 15px;
}
