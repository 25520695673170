@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
		padding-top:40px;

		.compared-vehicle-container {
			padding-top: 20px;
		}

		.single-vehicle {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		.subTitle{
			font-weight: 400;
			font-size: 20px;
			line-height: 24px;
			color: #707070;
			margin-top: 24px;
			font-family: 'proxima-nova';
		}
}