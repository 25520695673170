@import "src/client_customizations/scss/client_master.scss";

.EVsSort {
    margin-bottom: 0;
    height: 60px;
  label {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  select {
    border-radius: 0;
    box-sizing: border-box;
    border: 0.625828px solid #b0b0b0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: black;
    height: 48px;
  }
}
