@import "src/client_customizations/scss/client_master.scss";

.SelectedCard {
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #dbdbdb;
  border-radius: 0;
  min-height: 260px;

  .select-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pin-selected {
      height: 28px;
      width: 20px;
      margin-bottom: 20px;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #ee1c2e;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }

  .links-container {
    color: #005996;
    font-weight: 400;
    font-size: 14px;

    .col-6 {
      display: flex;
      align-items: center;
    }
    .row {
      > :first-child {
        padding-right: 10px;
        display: flex;
        justify-content: flex-end;
      }

      > :last-child {
        padding-left: 10px;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

.SelectedCard h5 {
  font-size: 1em;
}

.SelectedCard .address-container {
  color: #57595d;
  font-weight: 400;
}

.SelectedCard .oem-image {
  max-width: 150px;
  margin-bottom: 1.5em;
}
