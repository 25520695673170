@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
		position: "relative";
		background-repeat: "no-repeat";
		background-position: "center center";
		background-size: "cover";
		padding: 0;
		text-align: "center";
		box-sizing: "border-box";
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: "cover";
	}
}
#link-card-buttons {
	background: white;
}

#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

.incentives-top{
	display: flex;
    flex-direction: column;
    align-items: center;
}

.incentive-subtitle{
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #707070;
	max-width: 65ch;
	font-family: 'sans-serif';
}

.incentive-title{
	font-family: 'Questa';
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 4px !important;
}

.incentive-bg{
	background-image: url('https://i.imgur.com/yp76nGk.png') !important;
	min-height: 770px;
	padding-top: 64px !important;
	background-size: cover;
}