@import "src/client_customizations/scss/client_master.scss"; 

.ComparedCarDetails {
  margin: 1.6rem 0;
  text-align: center;
  .btn {
    margin-top: 28px;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    color: #005996;
    border: 1px solid #dbdbdb;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width: 1200px){
      width: 326px;
      margin: 28px 0 0 -18px;
    }
  }

  .title {
    font-weight: 500;
    font-size: 0.8rem;
    color: $darkGray;
    line-height: inherit;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
  }
  .afterIncentives 
  { .DetailColumn {

  }}
  .row {
    display: flex;
    justify-content: center;
  
    .DetailColumn {
      top: 0.4rem;
      padding: 0.5rem 0 1.12rem 0;
      border-bottom: 1px solid #dbdbdb;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .title{
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #000;
      }

      .subtitle{
        font-weight: 700;
        font-size: 24px;
        color: #000;
      }
    }
  }

  .h5 {
    font-size: 24px;
    font-weight: 600;
    color: $gray;
  }
}
