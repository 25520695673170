@import "src/client_customizations/scss/client_master.scss";

.cardIncentive {
	border-radius: 0px;
	width: 225px;
	min-height: 387px;
	background: white;
	box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
	margin-bottom: 72px;
	cursor: pointer;

}


.IncentiveCardBody {
	padding: 14px;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	height: 245px !important;

	.card-price{
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.card-preTitle{
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #333333;
	}

	.card-title {
		margin-top: 1rem;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;
		color: #333333;
	}

	.card-subTitle{
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
	}

	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
	.h6{
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
	}
}
.IncentiveCardBottom {
	padding: 12px 2px;
	text-align: left;
	border-top: 1px solid #DADADA;
	width: 90%;
}
@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 32%;
	}
	.IncentiveCardBody {

	}
}
