@import "src/client_customizations/scss/client_master.scss";

.hcFilterControls {
    border-radius: 0 !important;
    box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #ECECEC !important;

    .socket-text {
        float: left;
    }
    .socket-image {
        float: right;
    }

    .mainTitle{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }

    .filterTitle{
        margin: 0 !important;
        margin-bottom: 8px !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        
    }
}