@import "src/client_customizations/scss/client_master.scss";
$color_1: #666666;
$color_2: #333333;
$color_3: #252525;

.RenderItemTitle {
	font-weight: 500;
	font-size: 12px;
	color: $RenderItem-title-color;
	line-height: inherit;
	margin-bottom: 0;
	text-transform: uppercase;
}
.RenderItemValue {
	color: $RenderItem-item-value-color;
	.denominator {
		font-weight: 700;
		font-size: 0.8rem;
		color: $RenderItem-item-value-denominator-color;
	}
}
.h2.RenderItemValue {
	font-size: 1.75rem;
}
p.RenderItemValue {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}
